// Code generated by graphql-codegen. DO NOT EDIT.
import * as Types from '../../../generated/types'

import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type GetCompanyQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
}>

export type GetCompanyQuery = {
  readonly __typename?: 'Query'
  readonly company:
    | { readonly __typename?: 'Company'; readonly id: string; readonly name: string }
    | null
    | undefined
}

export const GetCompanyDocument = gql`
  query getCompany($id: ID!) {
    company(id: $id) {
      id
      name
    }
  }
`

export function useGetCompanyQuery(
  options: Omit<Urql.UseQueryArgs<GetCompanyQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetCompanyQuery, GetCompanyQueryVariables>({
    query: GetCompanyDocument,
    ...options,
  })
}
