import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

import { SkeletonGlobalNavigation } from '@/ui/skeleton-global-navigation/skeleton-global-navigation'

import { RequireLogin } from '@/features/auth/require-login/require-login'
import { GlobalNavigationContainer } from '@/features/navigation/global-navigation/global-navigation'

type Props = {
  children: ReactNode
}

export const SideBarLayout: FC<Props> = ({ children }) => {
  return (
    <Flex w="100%" h="100vh">
      <Grid templateColumns="1fr 4fr" alignItems="center" w="100%">
        <GridItem h="100%">
          <RequireLogin loading={<SkeletonGlobalNavigation />}>
            <GlobalNavigationContainer />
          </RequireLogin>
        </GridItem>

        <GridItem h="100%" p={6} overflowY="auto">
          {children}
        </GridItem>
      </Grid>
    </Flex>
  )
}
