export const pagesPath = {
  "admins": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/admins/[id]' as const, query: { id }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/admins' as const, hash: url?.hash })
  },
  "companies": {
    _id: (id: string | number) => ({
      "accounts": {
        _accountId: (accountId: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/companies/[id]/accounts/[accountId]' as const, query: { id, accountId }, hash: url?.hash })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/companies/[id]/accounts' as const, query: { id }, hash: url?.hash })
      },
      "managers": {
        _managerId: (managerId: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/companies/[id]/managers/[managerId]' as const, query: { id, managerId }, hash: url?.hash })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/companies/[id]/managers' as const, query: { id }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/companies/[id]' as const, query: { id }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/companies' as const, hash: url?.hash })
  },
  "login": {
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
