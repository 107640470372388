import { Center, Text, Flex, Spinner } from '@chakra-ui/react'
import { FC } from 'react'

type LoadingProps = {
  full?: boolean
  description?:
    | {
        type: 'none'
      }
    | {
        type: 'loadingData'
      }
    | {
        type: 'loadingAuth'
      }
    | {
        type: 'custom'
        text: string
      }
}

/**
 * FIXME: front-end/src/ui/loading と内容が同じ  uiパッケージを作ってfront-endとadminのどちらからも使用できるようにしたい
 */
export const Loading: FC<LoadingProps> = ({ full, description = { type: 'none' } }) =>
  full ? (
    <Center h={'100vh'}>
      <LoadingContent description={description} />
    </Center>
  ) : (
    <LoadingContent description={description} />
  )

/**
 * スピナーと説明文を表示する
 */
const LoadingContent: FC<Required<Pick<LoadingProps, 'description'>>> = ({ description }) => (
  <Flex
    align={'center'}
    justify={'center'}
    width={'full'}
    height={'full'}
    direction={'column'}
    gap={'8'}
    p={'10'}
  >
    <LoadingDescription description={description} />
    <Spinner size={'lg'} />
  </Flex>
)

/**
 * 説明文を出し分ける
 */
const LoadingDescription = ({
  description,
}: Required<Pick<LoadingProps, 'description'>>): JSX.Element | null => {
  switch (description.type) {
    case 'none':
      return null
    case 'loadingData':
      return <Text>{'データを取得しています'}</Text>
    case 'loadingAuth':
      return <Text>{'認証情報を確認しています'}</Text>
    case 'custom':
      return <Text>{description.text}</Text>
  }
}
