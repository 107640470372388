// Code generated by graphql-codegen. DO NOT EDIT.
import * as Types from '../../../generated/types'

import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type GetAdminQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetAdminQuery = {
  readonly __typename?: 'Query'
  readonly meByAdmin:
    | {
        readonly __typename?: 'Admin'
        readonly id: string
        readonly name: string
        readonly mailAddress: string
      }
    | null
    | undefined
}

export const GetAdminDocument = gql`
  query getAdmin {
    meByAdmin {
      id
      name
      mailAddress
    }
  }
`

export function useGetAdminQuery(
  options?: Omit<Urql.UseQueryArgs<GetAdminQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetAdminQuery, GetAdminQueryVariables>({
    query: GetAdminDocument,
    ...options,
  })
}
