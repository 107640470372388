import { useAuth0 } from '@auth0/auth0-react'
import { FC, ReactNode } from 'react'
import {
  cacheExchange,
  createClient,
  dedupExchange,
  Exchange,
  fetchExchange,
  Operation,
  Provider,
  Client,
} from 'urql'
import { fromPromise, fromValue, map, mergeMap, pipe } from 'wonka'

import { getEnv } from '@/utils/get-env'

type Props = {
  children: ReactNode
}

const isPromise = <T,>(obj: unknown): obj is Promise<T> =>
  obj instanceof Promise || (!!obj && typeof (obj as Promise<T>).then === 'function')

type FetchOptions = (() => RequestInit) | RequestInit

export const URQLProvider: FC<Props> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()

  const fetchOptionsExchange =
    (fn: (fetchOptions?: FetchOptions) => FetchOptions | Promise<FetchOptions>): Exchange =>
    ({ forward }) =>
    (ops$) => {
      return pipe(
        ops$,
        mergeMap((operation: Operation) => {
          const result = fn(operation.context.fetchOptions)
          return pipe(
            isPromise(result) ? fromPromise(result) : fromValue(result),
            map((fetchOptions: FetchOptions) => ({
              ...operation,
              context: { ...operation.context, fetchOptions },
            })),
          )
        }),
        forward,
      )
    }

  const client: Client = createClient({
    url: getEnv().NEXT_PUBLIC_API_ENDPOINT,
    requestPolicy: 'cache-and-network',
    exchanges: [
      dedupExchange,
      cacheExchange,
      fetchOptionsExchange(async (fetchOptions?: FetchOptions) => {
        const token = await getAccessTokenSilently()
        return Promise.resolve({
          ...fetchOptions,
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        })
      }),
      fetchExchange,
    ],
  })

  return <Provider value={client}>{children}</Provider>
}
