import { UrlObject } from 'url'

import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import NextLink from 'next/link'
import { ReactNode, FC, useMemo } from 'react'

type Props = {
  colorType?: 'primary' | 'secondary'
  href: string | UrlObject
  children?: ReactNode
  fontSize?: LinkProps['fontSize']
  active?: boolean
}

type ColorType = {
  color: '#0F7EFF' | 'gray.300'
  textDecoration: 'underline'
}

export const Link: FC<Props> = ({
  colorType = 'primary',
  href,
  fontSize = 'md',
  active,
  children,
}) => {
  const colorStyle = useMemo<ColorType>(() => {
    switch (colorType) {
      case 'primary':
        return {
          color: '#0F7EFF',
          textDecoration: 'underline',
        }
      case 'secondary':
        return {
          color: 'gray.300',
          textDecoration: 'underline',
        }
      default:
        fail(colorType)
    }
  }, [colorType])
  return (
    <NextLink href={href} passHref>
      <ChakraLink
        {...colorStyle}
        fontSize={fontSize}
        textDecoration="underline"
        padding={'0.5'}
        borderRadius={'sm'}
        backgroundColor={active ? 'blue.100' : undefined}
        aria-current={active ? 'page' : undefined}
      >
        {children}
      </ChakraLink>
    </NextLink>
  )
}
