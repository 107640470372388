import { Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, ReactElement, ReactNode, useMemo } from 'react'
import { RecoilRoot } from 'recoil'

import { pagesPath } from '@/libs/pathpida/$path'
import { URQLProvider } from '@/libs/urql/provider'

import { getEnv } from '@/utils/get-env'

import { SideBarLayout } from '@/layouts/side-bar.layout'

import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const router = useRouter()

  const isLoginPage = useMemo(
    () => router.pathname === pagesPath.login.$url().pathname,
    [router.pathname],
  )

  const getLayout = useMemo(
    () =>
      Component.getLayout ??
      ((page: ReactElement): ReactNode => <SideBarLayout>{page}</SideBarLayout>),
    [Component.getLayout],
  )

  const env = getEnv()

  return (
    <>
      <Head>
        <title>アガルートアカデミー for Biz | Admin</title>
        <meta name="description" content="アガルートアカデミー for Biz | Admin" />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="icon" type="image/svg+xml" href="/favicon.png" />
      </Head>
      <RecoilRoot>
        <Auth0Provider
          domain={env.NEXT_PUBLIC_AUTH0_DOMAIN}
          clientId={env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
          audience={env.NEXT_PUBLIC_AUTH0_AUDIENCE}
        >
          <URQLProvider>
            <ChakraProvider>
              {isLoginPage ? <Component {...pageProps} /> : getLayout(<Component {...pageProps} />)}
            </ChakraProvider>
          </URQLProvider>
        </Auth0Provider>
      </RecoilRoot>
    </>
  )
}

export default MyApp
