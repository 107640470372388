import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import { FC, ReactNode } from 'react'

import { pagesPath } from '@/libs/pathpida/$path'

import { Loading } from '@/ui/loading/loading'

import { SessionContextProvider } from '../session-context/session-context'

type Props = {
  children: ReactNode
  loading?: JSX.Element
}

export const RequireLogin: FC<Props> = ({
  children,
  loading = <Loading description={{ type: 'loadingAuth' }} />,
}) => {
  const { isLoading, isAuthenticated } = useAuth0()
  const { push } = useRouter()

  if (isLoading) {
    return loading
  }

  if (!isAuthenticated) {
    push(pagesPath.login.$url())

    return null
  }

  return <SessionContextProvider {...{ loading }}>{children}</SessionContextProvider>
}
