import { UrlObject } from 'url'

import { useRouter } from 'next/router'
import { FC } from 'react'

import { Link } from '@/ui/link/link'

type NavigationItemProps = {
  href: UrlObject
  includeChild?: boolean
  children: React.ReactNode
}

/**
 * 一階層上のパスを取得する
 * @example
 * const parentPath = getParentPath("/accounts/[id]") // -> "/account"
 */
const getParentPath = (pathname: string): string => {
  const paths = pathname.split('/')
  return paths.slice(0, paths.length - 1).join('/')
}

export const NavigationItem: FC<NavigationItemProps> = ({ href, includeChild, children }) => {
  const { pathname } = useRouter()

  const active = includeChild
    ? pathname === href.pathname || getParentPath(pathname) === href.pathname
    : pathname === href.pathname

  return (
    <Link href={href} fontSize={'md'} active={active}>
      {children}
    </Link>
  )
}
