import * as Sentry from '@sentry/nextjs'
import { useCallback } from 'react'

type UseSentry = () => {
  captureException: (
    message: string, // sentryに送信するメッセージ
    error?: Error, // catchしたErrorなどあればメッセージと一緒に送信する
  ) => void
}
export const useSentry: UseSentry = () => {
  const captureException = useCallback<ReturnType<UseSentry>['captureException']>(
    (message, error) => {
      Sentry.captureException(new Error(message, { cause: error }))
    },
    [],
  )

  return {
    captureException,
  }
}
