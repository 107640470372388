import { Stack, Flex, Box, Skeleton } from '@chakra-ui/react'
import { FC } from 'react'

type SkeletonGlobalNavigationProps = Record<string, never>

export const SkeletonGlobalNavigation: FC<SkeletonGlobalNavigationProps> = () => (
  <Stack direction={'column'} h="100%" bg="#EFEFEF" p={6} spacing={4}>
    <Box>
      <Skeleton height={'2.2rem'} />
    </Box>
    <Stack flex={'1 1 auto'} direction="column" h="100%">
      <Stack direction="column" gap={4}>
        <Flex direction="column" gap={2}>
          <Skeleton height={'1rem'} />
          <Skeleton height={'1rem'} />
        </Flex>
      </Stack>
    </Stack>
    <Stack direction="column">
      <Skeleton height={'16'} />
    </Stack>
  </Stack>
)
