import { useAuth0 } from '@auth0/auth0-react'
import { createContext, FC, ReactNode, useContext } from 'react'

import { pagesPath } from '@/libs/pathpida/$path'
import { useSentry } from '@/libs/sentry/use-sentry'

import { Loading } from '@/ui/loading/loading'

import { useGetAdminQuery } from './index.generated'

type Session = {
  admin: Admin
}
type Admin = {
  id: string
  name: string
  email: string
}

export const useSessionContext = (): Session => {
  const session = useContext(SessionContext)

  if (session === null) {
    throw new Error('`SessionContextProvider`の外側で`SessionContext`が使用されています')
  }

  return session
}

type Props = {
  children: ReactNode
  loading?: JSX.Element
}

export const SessionContextProvider: FC<Props> = ({
  children,
  loading = <Loading description={{ type: 'loadingAuth' }} />,
}) => {
  const [getAdminQuery, _refetch] = useGetAdminQuery()
  const { logout } = useAuth0()
  const { captureException } = useSentry()

  if (getAdminQuery.fetching) {
    return loading
  }

  const maybeAdmin = getAdminQuery.data?.meByAdmin

  if (!maybeAdmin || getAdminQuery.error !== undefined) {
    logout({
      returnTo: `${window.location.origin}${pagesPath.login.$url().pathname}`,
    })
    captureException('error at meByAdminQuery', getAdminQuery.error)

    return null
  }

  const session: Session = {
    admin: {
      id: maybeAdmin.id,
      name: maybeAdmin.name,
      email: maybeAdmin.mailAddress,
    },
  }

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
}

const SessionContext = createContext<Session | null>(null)
