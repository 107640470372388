import { useAuth0 } from '@auth0/auth0-react'
import { Stack, Flex, Box, Heading, Text } from '@chakra-ui/react'
import { FC, ReactNode, useCallback } from 'react'

import { pagesPath } from '@/libs/pathpida/$path'
import { useSentry } from '@/libs/sentry/use-sentry'

import { Button } from '@/ui/button/button'
import { Loading } from '@/ui/loading/loading'

import { useSessionContext } from '@/features/auth/session-context/session-context'
import { useGetCompanyId } from '@/features/company/use-get-company-id'
import { NavigationItem } from '@/features/navigation/navigation-item/navigation-item'

import { useGetCompanyQuery } from './index.generated'

export const GlobalNavigationContainer: FC = () => {
  const { logout } = useAuth0()
  const id = useGetCompanyId()

  const subContent = id !== null ? <CompanyNavigationContainer {...{ id }} /> : null
  const handleLogout = useCallback(() => {
    logout({
      returnTo: `${window.location.origin}${pagesPath.login.$url().pathname}`,
    })
  }, [logout])
  const { name, email } = useSessionContext().admin

  return (
    <GlobalNavigation
      subContent={subContent}
      handleLogout={handleLogout}
      userName={name}
      email={email}
    />
  )
}

type Props = {
  subContent: ReactNode
  handleLogout: () => void
  userName: string
  email: string
}
export const GlobalNavigation: FC<Props> = ({ subContent, handleLogout, userName, email }) => {
  return (
    <Stack direction={'column'} h="100%" bg="#EFEFEF" p={6} spacing={4}>
      <Box>
        <Heading fontSize="xl">ForBizAdmin</Heading>
      </Box>
      <Stack flex={'1 1 auto'} direction="column" h="100%">
        <Stack direction="column" gap={4}>
          <Flex direction="column" gap={2}>
            <NavigationItem href={pagesPath.companies.$url()}>企業</NavigationItem>
            <NavigationItem href={pagesPath.admins.$url()} includeChild>
              管理者
            </NavigationItem>
          </Flex>
          {subContent}
        </Stack>
      </Stack>
      <Stack direction="column">
        <Flex
          bg="white"
          p={4}
          gap={4}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          borderRadius={8}
        >
          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            <Text fontWeight="bold" fontSize="xs">
              {userName}
            </Text>
            <Text fontSize="xs">{email}</Text>
          </Box>
          <Box minWidth="96px">
            <Button colorType="secondary" size="sm" onClick={handleLogout}>
              ログアウト
            </Button>
          </Box>
        </Flex>
      </Stack>
    </Stack>
  )
}

type CompanyNavigationContainerProps = {
  id: string
}
const CompanyNavigationContainer: FC<CompanyNavigationContainerProps> = (props) => {
  const [companyQuery, _refetch] = useGetCompanyQuery({ variables: { id: props.id } })
  const { captureException } = useSentry()

  if (companyQuery.fetching) {
    return <Loading />
  }

  if (!companyQuery.data?.company) {
    return null
  }

  if (companyQuery.error !== undefined) {
    captureException('error at getCompanyQuery', companyQuery.error)

    return null
  }

  const company = {
    id: companyQuery.data.company.id,
    name: companyQuery.data.company.name,
  }

  return <CompanyNavigation {...{ company }} />
}

type CompanyNavigationProps = {
  company: { id: string; name: string }
}
export const CompanyNavigation: FC<CompanyNavigationProps> = ({ company }) => (
  <Flex direction="column" gap={2}>
    <Text mb={2} fontWeight="bold">
      {company.name}
    </Text>
    <NavigationItem href={pagesPath.companies._id(company.id).accounts.$url()} includeChild>
      受講生
    </NavigationItem>
    <NavigationItem href={pagesPath.companies._id(company.id).managers.$url()} includeChild>
      企業担当者
    </NavigationItem>
    {/* <NavigationItem href={pagesPath.companies._id(company.id).$url()}>
      メール
    </NavigationItem> */}
    <NavigationItem href={pagesPath.companies._id(company.id).$url()}>詳細</NavigationItem>
  </Flex>
)
