import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'
import { ReactNode, FC, useMemo, forwardRef } from 'react'

type Props = {
  colorType: 'primary' | 'secondary'
  children: ReactNode
} & Omit<ButtonProps, 'bg' | 'color' | 'fontWeight' | 'colorScheme' | 'variant'>

type ColorType = {
  colorScheme: 'teal' | 'gray'
  variant: 'solid' | 'outline'
}

export const Button: FC<Props> = forwardRef<HTMLButtonElement, Props>(function Button(
  { colorType, children, ...props },
  ref,
) {
  const colorStyle = useMemo<ColorType>(() => {
    switch (colorType) {
      case 'primary':
        return {
          colorScheme: 'teal',
          variant: 'solid',
        }
      case 'secondary':
        return {
          colorScheme: 'gray',
          variant: 'outline',
        }
      default:
        fail()
    }
  }, [colorType])
  return (
    <ChakraButton fontWeight="normal" {...colorStyle} {...props} ref={ref}>
      {children}
    </ChakraButton>
  )
})
